@import "../../../variables";
@import "../../../utilities/globalmixins";

.percentage-tile {
    padding-top: 2rem;
}

.circle,
.inner-padding {
    background-color: var(--color-white);
    margin: auto;
    @include border-radius(50%);
}

.inner-padding {
    width: 150px;
    height: 150px;
}

.circle {
    width: 134px;
    height: 134px;
    border: 8px solid $grey3;
    color: var(--color-dazzled-blue);

    .percentage {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 2rem;

        span {
            font-weight: 500;
            font-size: 2rem;
        }
    }

    .off-text {
        display: block;
        font-weight: 600;
    }
}
