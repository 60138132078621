@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin box-shadow($left, $top, $radius, $color) {
    box-shadow: $left $top $radius $color;
    -webkit-box-shadow: $left $top $radius $color;
    -moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
    transition: $property $duration $easing;
    -webkit-transition: $property $duration $easing;
    -moz-transition: $property $duration $easing;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
    border-radius: $topright $bottomright $bottomleft $topleft;
    -webkit-border-radius: $topright $bottomright $bottomleft $topleft;
    -moz-border-radius: $topright $bottomright $bottomleft $topleft;
}

@mixin border-style($top, $right, $bottom, $left) {
    border-top: 1px solid $top;
    border-right: 1px solid $right;
    border-bottom: 1px solid $bottom;
    border-left: 1px solid $left;
}

@mixin toggle-psudo-elements($color3,$width,$rotate) {
    border-bottom: solid 2px $color3;
    bottom: 4px;
    content: "";
    display: block;
    width: $width;
    height: 0;
    position: absolute;
    transform: rotate($rotate);
}

@mixin collaspe-toggle($color3,$rotate) {
    .toggle {
        box-sizing: border-box;
        height: 0.75rem;
        position: relative;
        transform: rotate($rotate);
        transition: transform 0.3s;
        width: 0.75rem;

        &::before {
            @include toggle-psudo-elements ($color3,0,90deg);
        }

        &::after {
            @include toggle-psudo-elements ($color3,0.75rem,0);
        }
    }

    .collapsed .toggle {
        transform: rotate(0deg);

        &::before {
            @include toggle-psudo-elements ($color3, 0.75rem,90deg);

            transition: width 0.3s;
        }
    }
}

@mixin transition-animations($width) {
    flex: 0 0 $width;
    width: $width;
    opacity: 1;
    transform: translateZ(0);
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, flex-basis 0.4s ease-in-out, -webkit-flex-basis 0.4s ease-in-out, -ms-flex-preferred-size 0.4s ease-in-out;
    visibility: inherit;
}

// REM calculation
$html-font-size: 16px;

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue...) {
    $remval: ();
    @each $val in $pxValue {
        $remval: append(
            $remval,
            #{strip-unit($val) / strip-unit($html-font-size)}rem
        );
    }
    @return $remval;
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}